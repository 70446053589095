<template>
  <div class="member-gander-overview">
    <PageTitle title="性別" hideBtn />
    <div class="updateAt">
      <p>{{ updateAt }}</p>
      <p>{{ updateTime }}</p>
    </div>
    <section class="flex flex-col gap-10">
      <div class="flex gap-5">
        <TotalPurchaseStatistics
          class="chart-block flex-1"
          @getTime="getTime"
        />
        <TotalOrderStatistics class="chart-block flex-1" />
      </div>
      <div class="flex gap-5">
        <AverageConsumptionStatistics class="chart-block flex-1" />
        <AverageOrderStatistics class="chart-block flex-1" />
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import TotalPurchaseStatistics from './components/TotalPurchaseStatistics/TotalPurchaseStatistics.vue'
import TotalOrderStatistics from './components/TotalOrderStatistics/TotalOrderStatistics.vue'
import AverageConsumptionStatistics from './components/AverageConsumptionStatistics/AverageConsumptionStatistics.vue'
import AverageOrderStatistics from './components/AverageOrderStatistics/AverageOrderStatistics.vue'

export default defineComponent({
  name: 'MemberGanderOverview',
  components: {
    TotalPurchaseStatistics,
    TotalOrderStatistics,
    AverageConsumptionStatistics,
    AverageOrderStatistics,
  },
  setup () {
    const time = ref(null)

    const updateAt = computed(() => {
      return `數據截止日：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD') : '-'
      }`
    })
    const updateTime = computed(() => {
      return `數據更新時間：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD HH:mm:ss') : '-'
      }`
    })
    const getTime = (e) => {
      time.value = e
    }
    return {
      getTime,
      updateAt,
      updateTime,
    }
  },
})
</script>

<style lang="postcss" scoped>
.updateAt {
  @apply flex justify-end gap-[20px];
  @apply text-[#767676];
  @apply pb-[34px];
}
.chart-block {
  @apply bg-[#fff];
  @apply p-5;
  @apply rounded;
}
</style>
